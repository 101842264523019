import {
  AnalyticsPageRouting,
  AnalyticsProvider,
  useTrackHideIntercomWidget,
  useTrackShowIntercomWidget,
} from '@air/marketing-analytics';
import { ChilipiperScript } from '@air/plasmic-chilipiper';
import type { ComponentRenderData } from '@plasmicapp/loader-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { NextSeo, NextSeoProps } from 'next-seo';
import { ReactNode, useEffect } from 'react';

import { GoogleTagManager } from '~/scripts/GoogleTagManager';
import { isLocal } from '~/utils/isLocal';

export type PlasmicLayoutProps = {
  children: ReactNode;
  plasmicSeoProps: NextSeoProps;
  plasmicData: ComponentRenderData;
};

export const PlasmicLayout = ({ children, plasmicSeoProps, plasmicData }: PlasmicLayoutProps) => {
  if (typeof window !== 'undefined' && window.location.hostname === 'air.inc' && !window.hasLogRocketInitialized) {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET!, {
      console: {
        isEnabled: isLocal(),
      },
      dom: {
        /** @see https://app.logrocket.com/0mjm6m/air/settings/privacy/ */
        inputSanitizer: true,
      },
      network: {
        requestSanitizer: (request) => {
          // Don't share payment info
          if (request.url.includes('stripe')) {
            return null;
          }

          // Don't share Authorization header
          request.headers.Authorization = undefined;

          // Hide body from auth-related requests
          if (
            request.body?.includes('password') ||
            request.body?.includes('Password') ||
            request.body?.includes('AuthParameters')
          ) {
            request.body = undefined;
          }

          return request;
        },
      },
    });

    setupLogRocketReact(LogRocket);

    LogRocket.getSessionURL((sessionURL) => {
      // TODO: Integrate after https://github.com/AirLabsTeam/analytics/pull/15/files
      // if (!!window.mixpanel) {
      //   console.info('LogRocket - integrating mixpanel');
      //   window.mixpanel.track('LogRocket', { sessionURL });
      // }

      if (!!window.Intercom) {
        console.info('LogRocket - integrating intercom');
        window.Intercom('trackEvent', 'LogRocket', { sessionURL });
      }

      if (!!window.ga) {
        console.info('LogRocket - integrating google analytics');
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'LogRocket',
          eventAction: sessionURL,
        });
      }
    });

    window.hasLogRocketInitialized = true;
  }
  const { trackShowIntercomWidget } = useTrackShowIntercomWidget();
  const { trackHideIntercomWidget } = useTrackHideIntercomWidget();

  // Studio users can attach arbitrary key-value metadata to any page/component.
  const studioMeta = plasmicData?.entryCompMetas[0]?.metadata;

  useEffect(() => {
    if (studioMeta?.enableIntercom === 'true') {
      trackShowIntercomWidget();

      return () => trackHideIntercomWidget();
    }
  }, [studioMeta?.enableIntercom, trackShowIntercomWidget, trackHideIntercomWidget]);

  return (
    <AnalyticsProvider>
      <NextSeo {...plasmicSeoProps} />
      <GoogleTagManager />
      <ChilipiperScript />
      <AnalyticsPageRouting />
      {children}
    </AnalyticsProvider>
  );
};
