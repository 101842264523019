import {
  ComponentRenderData,
  extractPlasmicQueryData,
  PlasmicComponent,
  PlasmicRootProvider,
} from '@plasmicapp/loader-nextjs';
import type { GetStaticPaths, GetStaticProps } from 'next';
import NextError from 'next/error';
import { useRouter } from 'next/router';

import { REVALIDATE } from '~/constants/revalidate';
import { PlasmicLayout } from '~/layouts/PlasmicLayout';
import { Plasmic } from '~/lib/plasmic/Plasmic';

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await Plasmic.fetchPages();

  return {
    paths: pages.map((page) => ({
      params: {
        catchall: page.path.substring(1).split('/'),
      },
    })),
    /**
     * We use `false` instead of `blocking` because there's a bug in our current version of Next.js
     * that prevents redirects from working properly with `blocking`.
     */
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { catchall } = context.params ?? {};
  const plasmicPath =
    typeof catchall === 'string' ? catchall : Array.isArray(catchall) ? `/${catchall.join('/')}` : '/';
  const plasmicData = await Plasmic.maybeFetchComponentData(plasmicPath);

  if (!plasmicData) {
    return {
      props: {},
      revalidate: REVALIDATE,
    };
  }

  const pageMeta = plasmicData.entryCompMetas[0];

  const queryCache = await extractPlasmicQueryData(
    <PlasmicRootProvider loader={Plasmic} prefetchedData={plasmicData} pageParams={pageMeta.params} skipFonts>
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>,
  );

  return {
    props: {
      plasmicData,
      queryCache,
    },
    revalidate: REVALIDATE,
  };
};

export default function CatchallPage({
  plasmicData,
  queryCache,
}: {
  plasmicData?: ComponentRenderData;
  queryCache?: Record<string, any>;
}) {
  const router = useRouter();

  if (!plasmicData || plasmicData.entryCompMetas.length === 0) {
    return <NextError statusCode={404} />;
  }

  const pageMeta = plasmicData.entryCompMetas[0];

  return (
    <PlasmicRootProvider
      loader={Plasmic}
      pageParams={pageMeta.params}
      pageQuery={router.query}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      skipFonts
    >
      <PlasmicComponent component={pageMeta.name} />
    </PlasmicRootProvider>
  );
}

CatchallPage.layout = PlasmicLayout;
