const getWebHostname = () => {
  return (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname &&
    window.location.hostname.toLowerCase()
  );
};

export const isLocal = () => getWebHostname() === 'localhost';
